<template>
  <page-container :title="pageTitle" :breadcrumb="breadcrumb">
    <div class="box-container" style="margin-top: 10px;">
      <div class="box-container-inner">
        <div class="simple-query">
          <a-form-model ref="queryForm" :model="queryParams" layout="inline" class="query-form">
            <a-form-model-item label="公司/项目" prop="monitorpointname" style="width: 30%">
              <a-cascader :field-names="{ label: 'userdepname', value: 'userdepid', children: 'children' }" :options="userInfo.usertype!='0'?deptOptions.filter(item => item.deptype=='1'):deptOptionsAll.filter(item => item.deptype=='1')" v-model="userdepidCascaderSelected" :load-data="userInfo.usertype!='0'?deptOnLoadChildren:deptOnLoadChildrenAll" placeholder="请选择公司/项目" :change-on-select="true" :allow-clear="false"></a-cascader>
            </a-form-model-item>
            <a-form-model-item label="查询时段" prop="queryInstallDate" style="width: 30%">
              <a-range-picker v-model="queryInstallDate" :default-value="[moment(`${queryParams.starttime.substring(0,10)}`, 'YYYY-MM-DD'), moment(`${queryParams.endtime.substring(0,10)}`, 'YYYY-MM-DD')]"></a-range-picker>
            </a-form-model-item>
            <div class="query-btns" style="float: right;width: 20%">
              <a-button type="primary" @click="getAbnomalNumData(true)">当年</a-button>
              <a-button class="after-btn" @click="resetQueryParams">重置</a-button>
              <a-button type="primary" class="after-btn" @click="getAbnomalNumData()">查询</a-button>
            </div>
          </a-form-model>
        </div>
      </div>
    </div>
    <div class="box-container" style="margin-top: 10px;">
      <div class="box-container-inner">
        <div class="table-header">
          <h3 class="table-title">异常总览</h3>
        </div>
        <div class="bar" id="bar_left" style="height: 700px;width: 100%"></div>
      </div>
    </div>
  </page-container>
</template>

<script>
import moment from 'moment'
import {getAbnormalRecordAnalysis} from "A/wlw";
import * as echarts from "echarts";
import {getTimeRange} from "U/index";
import {mapState} from "vuex";
import deptselect from "@/mixins/deptselect";
export default {
  name: "abnormalAnalysis",
  mixins: [deptselect],
  data(){
    return{
      moment,
      userdepidCascaderSelected: [],
      xDataList:[],
      xLabelList:[],
      xLabelMap:{},
      maxXNum:null,
      xInterval:null,
      yDataList:[],
      total:'',
      maxYNum:null,
      yInterval:null,
      queryInstallDate:null,
      queryParams:{
        monitorpointname:'',
        starttime:'',
        endtime:'',
      }
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.userInfo
    }),
    pageTitle() {
      return "异常总览"
    },
    breadcrumb() {
      const pages = [{name:"数据分析与报告",path:""}]
      pages.push({name:this.pageTitle,path:""})
      return pages
    },
  },
  watch:{
    queryInstallDate(val) {
      let {start, end} = getTimeRange(val);
      this.queryParams.starttime = start;
      this.queryParams.endtime = end;
    },
    userdepidCascaderSelected(val) {
      if(val && val.length) {
        this.queryParams.userdepsid = val[val.length-1];
      }else {
        this.queryParams.userdepsid = '';
      }
    },
  },
  created() {
    let time = new Date();
    let monthNum = moment(time).format("YYYY-MM").slice(5);
    this.queryParams.starttime = moment(time).month(monthNum - 1).date(1).startOf("month").format("YYYYMMDD");
    this.queryParams.endtime = moment(time).month(monthNum - 1).date(1).endOf("month").format("YYYYMMDD");
    this.getAbnomalNumData()
    this.initDeptOptionsAll();
  },
  methods:{
    resetQueryParams() {
      this.queryInstallDate = [];
      this.userdepidCascaderSelected=[]
      this.$refs.queryForm.resetFields();
    },
    resetEchartsData(){
      this.xDataList=[]
      this.xLabelList=[]
      this.xLabelMap={}
      this.maxXNum=null
      this.xInterval=null
      this.yDataList=[]
      this.maxYNum=null
      this.yInterval=null
    },
    getAbnomalNumData(flag){
      if(flag){
        this.queryParams.starttime=moment(new Date()).format("YYYY")+"0101"
        this.queryParams.endtime=moment(new Date()).format("YYYY")+"1231"
      }
      let params = {
        ...this.queryParams
      }
      getAbnormalRecordAnalysis(params).then(res=>{
        if(res&&res.returncode=='0'){
          this.resetEchartsData()
          for(let i=0;i<res.abnormalList.length;i++){
            this.xDataList.push(res.abnormalList[i].num)
            this.xLabelList.push(res.abnormalList[i].eventname)
          }
          this.maxXNum=res.abnormalList[0].num
          this.total=res.abnormalList[0].total

          let base = 5
          while(this.maxXNum>base){
            base=base+5
          }
          this.maxXNum=base
          this.xInterval=this.maxXNum/5
          this.calcData()

          let that=this
          setTimeout(()=>{
            that.initCharts()
          },500)
        }
      })
    },
    calcData(){
      let totalNum=this.total
      let yData=0;
      // for(let i=0;i<this.xDataList.length;i++){
      //   totalNum+=parseInt(this.xDataList[i])
      // }
      for(let i=0;i<this.xDataList.length;i++){
        yData+=this.xDataList[i]/totalNum*100
        this.yDataList.push(yData.toFixed(2))
      }
    },
    initCharts(){
      let chartDom = document.getElementById('bar_left');
      let myChart = echarts.init(chartDom);
      let option;
      option = {
        title:{
          x:"center",
          show: false, //是否显示
          text: "异常历史",
          textStyle: {
            color: "#000000", // 主标题文字的颜色。
            fontSize: 18, // 字体大小
            fontWeight:'bold',
          },
          textAlign:"center",
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            crossStyle: {
              color: '#e20937'
            }
          }
        },
        toolbox: {
          feature: {
            // dataView: { show: true, readOnly: false },
            // magicType: { show: true, type: ['line', 'bar'] },
            // restore: { show: true },
            // saveAsImage: { show: true }
          },
          iconStyle:{
            normal:{
              color:'#000000',//设置颜色
            }
          }
        },
        legend: {
          // data: ['报警情况', '增量图'],
          // textStyle:{
          //   color: '#000000',
          // }
        },
        xAxis: [
          {
            type: 'category',
            data: this.xLabelList,
            axisPointer: {
              type: 'shadow'
            },
            axisLabel:{
              show:true,
              formatter:function(value)
              {
                var ret = "";//拼接加\n返回的类目项
                var maxLength = 3;//每项显示文字个数
                var valLength = value.length;//X轴类目项的文字个数
                var rowN = Math.ceil(valLength / maxLength); //类目项需要换行的行数
                if (rowN > 1)//如果类目项的文字大于3,
                {
                  for (var i = 0; i < rowN; i++) {
                    var temp = "";//每次截取的字符串
                    var start = i * maxLength;//开始截取的位置
                    var end = start + maxLength;//结束截取的位置
                    //这里也可以加一个是否是最后一行的判断，但是不加也没有影响，那就不加吧
                    temp = value.substring(start, end) + "\n";
                    ret += temp; //凭借最终的字符串
                  }
                  return ret;
                }
                else {
                  return value;
                }
              },
              textStyle:{
                color: '#000000',
                fontWeight:'bold',
              }
            },
          }
        ],
        yAxis: [
          {
            // show:false,
            type: 'value',
            // name: '异常情况',
            nameTextStyle: {color:'#000000'},
            min: 0,
            max: this.maxXNum,
            interval: this.xInterval,
            axisLabel: {
              formatter: '{value} 次',
              textStyle:{
                color: '#000000',
                fontSize: 15,
                fontWeight:'bold',
              }
            },
          },
          {
            // show:false,
            type: 'value',
            // name: '增量图',
            nameTextStyle: {color: '#ffffff'},
            min: 0,
            max: 100,
            interval: 20,
            axisLabel: {
              formatter: '{value} %',
              textStyle: {
                color: '#000000',
                fontSize: 15,
                fontWeight: 'bold',
              }
            },
            splitArea : {show : false},  //去除网格区域
            splitLine: {//去除网格线
              show: true,
              lineStyle: {
                color: ['rgb(237,125,49)'],
                width: 2,
                type: 'solid'
              }
            }
          }
        ],
        series: [
          {
            // name: '异常情况',
            type: 'bar',
            tooltip: {
              valueFormatter: function (value) {
                return value + ' 次';
              }
            },
            data: this.xDataList,
          },
          {
            // name: '增量图',
            type: 'line',
            yAxisIndex: 1,
            tooltip: {
              valueFormatter: function (value) {
                return value + ' %';
              }
            },
            data: this.yDataList,
            itemStyle: {
              color: '#BD4D49',
            },
            lineStyle: {
              width: 3,
              type: 'solid',
            },
          }
        ]
      };
      option && myChart.setOption(option);
    }
  }
}
</script>

<style scoped>
.bar{
  pointer-events: auto;
}
</style>